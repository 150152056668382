import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";

import { SimpleAccordion } from "../shared/accordion";

import { generateKeys } from "../../utilities/encrypt";
import { updateKeys } from "../../store/userSlice";

import { api } from "../shared/api";
import { Container } from "@mui/system";
import _ from "underscore";
import { Typography } from "@mui/material";

export const ApiKeys = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [apiKeys, setApiKeys] = useState([]);

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    getApiKeys();
  }, []);

  const createApiKey = () => {
    api.post(`/api/v1/apikeys`, {}, config).then((res) => {
      setApiKeys([...apiKeys, res.data]);
    })
  }

  const getApiKeys = () => {
    api.get(`/api/v1/apikeys?_${Date.now()}`, config).then((res) => {
      setApiKeys(res.data);
    });
  }

  const showApiKeys = () => {
    return apiKeys.map((m) => {
      return (
        <tr key={m.apikey}>
          <td>{m.api_key}</td>
          <td>{new Date(m.created_at).toLocaleString()}</td>
          <td>delete</td>
        </tr>
      );
    });
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Api Keys</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={createApiKey} variant="contained">
            Create API Key
          </Button>
        </Grid>
        <Grid item xs={12}>
          <table>
            <thead style={{fontWeight: 'bold'}}>
              <tr>
                <td>API Key</td>
                <td>Created At</td>
              </tr>
            </thead>
            <tbody>
              { showApiKeys() }
            </tbody>
          </table>
        </Grid>
      </Grid>
    </Container>
  );
};
