import * as openpgp from 'openpgp';
// It is recommended to use curve25519 instead of brainpoolP512r1 by default.

// Generate user public/private keys
export const generateKeys = () => {
  return (async () => {
    const { privateKey, publicKey, revocationCertificate } = await openpgp.generateKey({
        type: 'ecc', // Type of the key, defaults to ECC
        curve: 'curve25519', // ECC curve name, defaults to curve25519
        userIDs: [{}], //[{ name: 'Jon Smith', email: 'jon@example.com' }], // you can pass multiple user IDs
        passphrase: `salt`, // protects the private key
        format: 'armored' // output key format, defaults to 'armored' (other options: 'binary' or 'object')
    });

    let fingerprint = await (await openpgp.readKey({armoredKey: publicKey})).getFingerprint();

    //console.log(privateKey);     // '-----BEGIN PGP PRIVATE KEY BLOCK ... '
    //console.log(publicKey.fingerprint);      // '-----BEGIN PGP PUBLIC KEY BLOCK ... '
    //console.log(revocationCertificate); // '-----BEGIN PGP PUBLIC KEY BLOCK ... '

    let records = {
      private_key: privateKey,
      public_key: publicKey,
      revocation_cert: revocationCertificate,
      public_fingerprint: fingerprint
    }

    return records;
  })();
}

export const encrypt = async({plain_message, receiver_publicKey, sender_privateKey}) => {
  // put keys in backtick (``) to avoid errors caused by spaces or tabs

  //console.log({plain_message, receiver_publicKey, sender_privateKey});
  const publicKeyArmored = receiver_publicKey;
  const privateKeyArmored = sender_privateKey;
  const passphrase = `salt`; // all passphrases are empty

  const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });


  /*
  const privateKey = await openpgp.decryptKey({
      privateKey: await openpgp.readPrivateKey({ armoredKey: privateKeyArmored }),
      passphrase
  });
  */

  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({ text: plain_message }), // input as Message object
    encryptionKeys: publicKey,
    //signingKeys: privateKey // optional
  });

  return encrypted;
}

export const decrypt = async({encrypted_message, receiver_privateKey}) => {
  // put keys in backtick (``) to avoid errors caused by spaces or tabs
  // console.log("inside decrypt", {encrypted_message, receiver_privateKey});

  const privateKeyArmored = receiver_privateKey;
  const passphrase = `salt`

  const privateKey = await openpgp.decryptKey({
    privateKey: await openpgp.readPrivateKey({ armoredKey: privateKeyArmored }),
    passphrase
  });


  if(encrypted_message !== null) {
    try {
      const message = await openpgp.readMessage({
        armoredMessage: encrypted_message // parse armored message
      });

      const { data: decrypted, signatures } = await openpgp.decrypt({
        message,
        decryptionKeys: privateKey
        //verificationKeys: publicKey, // optional
      });
      return decrypted;
    } catch(error) {
      console.log("armoredMessage", error, encrypted_message);
    }
  }

  
  //console.log([privateKeyArmored, passphrase, privateKey, message, encrypted_message, decrypted]);
  return '[Encrypted]';
}