import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";

import { SimpleAccordion } from "../shared/accordion";

import { generateKeys } from "../../utilities/encrypt";
import { updateKeys } from "../../store/userSlice";

import { api } from "../shared/api";
import { Container } from "@mui/system";
import _ from "underscore";
import { Typography } from "@mui/material";

export const UserSettings = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  const createAccordion = () => {
    if (!_.isEmpty(user.PKEkeys)) {
      let records = [
        { title: "Public Key", body: user.PKEkeys.public_key },
        { title: "Private Key", body: user.PKEkeys.private_key },
        { title: "Revocation Certificate", body: user.PKEkeys.revocation_cert },
      ];
      return (
        <>
          <p>Fingerprint: {user.PKEkeys.public_fingerprint}</p>
          <SimpleAccordion records={records} />
        </>
      );
    }
  };

  const renewKeys = () => {
    generateKeys().then((PKEkeys) => {
      api.post("/api/v1/settings/keys", PKEkeys, config).then((res) => {
        dispatch(updateKeys(PKEkeys));
      });
    });
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Encryption Keys</Typography>
        </Grid>
        <Grid item xs={12}>
          {createAccordion()}
        </Grid>
        <Grid item xs={12}>
          <Button onClick={renewKeys} variant="contained" fullWidth>
            Generate New Keys
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
