import { Avatar, Button, Card, CardActions, CardContent, Container, Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";
import { api } from "../shared/api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const owner = {
  border: '1px solid red',
}

const subscribed = {
  border: '1px solid blue',
}

const unsubscribed = {
  border: '1px solid black',
}

const flexContainer = {
  display: 'flex',
  flexDirection: 'row', 
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}
export const AppsIndex = () => {
  const user = useSelector((state) => state.user);

  const [apps, setApps] = useState([]);

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    api.get(`/api/v1/apps/?_=${Date.now()}`, config).then((res) => {
      console.log(res.data);
      setApps(res.data);
    })
  }, []);

  const renderApps = () => {
    return apps.map((record) => {
      return (
        <Card>
          <CardContent>
            <Avatar>{record.display_name[0]}</Avatar>
            <Typography variant="h6">{record.display_name}</Typography>
            <Typography variant="body1">{record.bio}</Typography>
          </CardContent>
          <CardActions>
            <Link to={`/apps/${record.id}/`}>
              <Button size="small">Learn More</Button>
            </Link>
          </CardActions>
        </Card>       
      );
    });
  }


  return (
    <Container>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h5">Apps</Typography>
        </Grid>
        <Grid xs={12} sx={flexContainer}>
          { renderApps() }
        </Grid>

      </Grid>
    </Container>
  )
}