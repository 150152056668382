export const zh = {
  translation: {
    Home: {
      hero: {
        about1: 'Salt 是一个集成的加密和加密货币聊天应用程序和平台。',
        about2: '通过私人消息与任何人聊天和发送加密货币。',
        webapp: '网络应用',
      },
      downloads: {
        title: '下载',
      },
      body: {
        chat1: '完全安全的私人消息',
        chat2: '使用 OpenPGP PKE 密码学加密',
        crypto1: '各种加密硬币和代币钱包',
        crypto2: '目前支持：比特币、以太币、狗狗币',
        profiles1: '验证帐户',
        profiles2: '评论和评级',
        api1: '构建任何类型的机器人',
        api2: '开发人员 API 可集成您能想到的任何内容'
      }
    }
  }
}