import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import { ChildModal } from "../shared/modal";
import { Button, List, ListItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import _ from "underscore";

export const ChatInfo = ({ chat }) => {
  const [openModal, setOpenModal] = useState(false);

  //console.log(chat);

  const renderUsers = () => {
    if (!_.isEmpty(chat)) {
      return chat.session.users.map((participant) => {
        return (
          <ListItem>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography component="div">
                  {participant.display_name} (@{participant.username})
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Typography sx={{ fontSize: "6pt", whiteSpace: "pre-wrap" }}>
                  {participant.public_key}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      });
    }
  };

  const renderModal = () => {
    return (
      <ChildModal openModal={openModal} setOpenModal={setOpenModal}>
        <Typography variant="h6">Users</Typography>
        <List>{renderUsers()}</List>
        <div>
          <Button
            variant="contained"
            onClick={() => setOpenModal(false)}
            fullWidth
          >
            Close
          </Button>
        </div>
      </ChildModal>
    );
  };

  return (
    <>
      <IconButton color="inherit" onClick={() => setOpenModal(true)}>
        <MoreIcon />
      </IconButton>
      {renderModal()}
    </>
  );
};
