import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChildModal } from "../../shared/modal";
import { Button, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import _ from "underscore";

import { api } from "../../shared/api";
import { createErc20Tx, createSignedTx, createTx } from "../../../utilities/ethereum";
import { CurrencyTokenSelect } from "./currencyTokenSelect";
import { CurrencyWalletSelect } from "./currencyWalletSelect";
import { CurrencyReceiverSelect } from "./currencyReceiverSelect";

export const CurrencyModal = ({ chat, openModal, setOpenModal, receivers }) => {
  const [selectedSenderToken, setSelectedSenderToken] = useState({});
  const [selectedSenderWallet, setSelectedSenderWallet] = useState({});
  const [selectedReceiverWallet, setSelectedReceiverWallet] = useState({});

  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState();

  const [title, setTitle] = useState("");

  const [receiver, setReceiver] = useState({
    display_name: "",
    wallets: [0],
  });

  const wallets = useSelector((state) => state.wallets);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    buildTitle();
  }, [selectedSenderWallet, selectedSenderToken]);

  const submitTransfer = () => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };

    // flatten wallets
    let wlts = [];
    for (var key in wallets) {
      wlts.push(wallets[key]);
    }
    let flt_wlts = _.flatten(wlts);
    let sender_wallet = _.find(flt_wlts, (x) => {
      return x.id === selectedSenderWallet.id;
    });
    api
      .get(`/api/v1/wallets/${selectedSenderWallet.id}/rpcinfo`, config)
      .then((res) => {
        console.log("INSIDE RPC CALL", res.data);
        let nonce = res.data.nonce;
        let gas_price = res.data.gas_price;
        let tx = null;

        console.log("CM", nonce, gas_price);

        console.log("tx token", selectedSenderToken);

        if(!_.isEmpty(selectedSenderToken)) {
          tx = createErc20Tx({
            sender_wallet: sender_wallet,
            receiver_wallet: selectedReceiverWallet,
            amount: amount,
            nonce: nonce,
            gas_price: gas_price,
          });
        } else {
          console.log([selectedReceiverWallet, amount, nonce, gas_price]);
          tx = createTx({
            receiver_wallet: selectedReceiverWallet,
            amount: amount,
            nonce: nonce,
            gas_price: gas_price,
          });
        }

        console.log(tx);

        tx.then((tx) => {
          let signed_tx = createSignedTx({
            sender_wallet: sender_wallet,
            tx: tx,
          });

          // search by id
          // sign transaction
          signed_tx.then((signed_transfer_request) => {
            const params = {
              amount: amount,
              wallet_id: selectedSenderWallet.id,
              sender_id: user.session.id,
              receiver_id: receiver.id,
              signed_transfer_request: signed_transfer_request,
              chat_id: chat.session.id,
              message: message,
              receiver_wallet_id: selectedReceiverWallet.id,
            };

            api.post(`/api/v1/transfers/`, params, config).then((res) => {
              console.log(res.data);
                setOpenModal(false);
            });
          });
        });

        /*
      api.post(`/api/v1/ethereum/gas_estimate`, {transfer: tx}, config).then((res) =>{
        let gas_estimate = res.data['result'];
        tx.gasPrice = gas_estimate;

        let signed_tx = createSignedTx({sender_wallet: sender_wallet, tx: tx})

        // search by id
        // sign transaction
        signed_tx.then((signed_transfer_request) => {
          const params = {
            amount: amount,
            wallet_id: selectedSenderWalletId,
            sender_id: user.session.id,
            receiver_id: receiver.id,
            signed_transfer_request: signed_transfer_request,
            chat_id: chat.id,
            message: message,
            receiver_wallet_id: receiver.wallets[0].id,
          };

          api.post(`/api/v1/transfers/`, params, config).then((res) => {
            console.log(res.data);
            setOpenModal(false);
          });
        });
      })
      */
      });
  };

  useEffect(() => {
    buildReceivers();
  }, [chat]);

  const buildReceivers = () => {
    if (!_.isEmpty(chat)) {
      let others = chat.session.users.filter((rcvr) => {
        return rcvr.id !== user.session.id;
      });

      let myself = chat.session.users.filter((rcvr) => {
        return rcvr.id === user.session.id;
      });

      if (others.length === 0) {
        setReceiver(myself);
      } else {
        setReceiver(others[0]);
      }
    }
  };

  const buildTitle = () => {
    if (!_.isEmpty(selectedSenderWallet)) {
      if (!_.isEmpty(selectedSenderToken)) {
        setTitle(selectedSenderToken.token_shortname);
      } else {
        setTitle(selectedSenderWallet.name_3);
      }
    } else {
      setTitle("");
    }

    //console.log("wallet", selectedSenderWallet);
    //console.log("token", selectedSenderToken);
  };

  const buildModal = () => {
    if (!_.isEmpty(chat)) {
      return (
        <ChildModal openModal={openModal} setOpenModal={setOpenModal}>
          <Grid container spacing={2} sx={{ padding: 1 }}>
            <Grid xs={12}>
              <Typography variant="h6">Send</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography>
                {user.session.display_name}{" "}
                <ArrowRightIcon sx={{ top: "6px", position: "relative" }} />{" "}
                {receiver.display_name}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <CurrencyWalletSelect
                setSelectedWallet={setSelectedSenderWallet}
              />
            </Grid>
            <Grid xs={12}>
              <CurrencyTokenSelect
                selectedWallet={selectedSenderWallet}
                selectedToken={selectedSenderToken}
                setSelectedToken={setSelectedSenderToken}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label={`Send ${title} Amount`}
                variant="filled"
                fullWidth
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Note"
                multiline
                variant="filled"
                rows={2}
                fullWidth
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Grid>
            <Grid xs={12}>
              <div style={{marginBottom: '5px'}}>To:</div>
                <CurrencyReceiverSelect
                  setSelectedReceiverWallet={setSelectedReceiverWallet} 
                  wallets={receiver.wallets}
                />
            </Grid>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <Button
                variant="outlined"
                onClick={() => setOpenModal(false)}
                sx={{
                  marginRight: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={submitTransfer}
                disabled={
                  !!!(
                    "wallets" in receiver &&
                    receiver.wallets.length > 0 &&
                    !_.isEmpty(selectedSenderWallet)
                  )
                }
              >
                Send {title}
              </Button>
            </Grid>
          </Grid>
        </ChildModal>
      );
    }
  };

  return <>{buildModal()}</>;
};