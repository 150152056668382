import { IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { api } from "../shared/api";

import { deleteWallet } from "../../store/walletSlice";
import { useState } from "react";

export const RemoveWallet = ({ wallet }) => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const removeWallet = () => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };
    api
      .delete(`/api/v1/wallets/${wallet.id}`, config)
      .then((res) => {
        dispatch(
          deleteWallet({
            wallet_type: wallet.wallet_type,
            wallet_id: wallet.id,
          })
        );
        handleClose();
      })
      .catch((err) => {
        dispatch(
          deleteWallet({
            wallet_type: wallet.wallet_type,
            wallet_id: wallet.id,
          })
        );
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmModal = () => {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">
            {`⚠️ Delete wallet?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                {wallet.public_address}
              </Typography>
              💀 You cannot undo this. 💀
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={() => removeWallet()} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {confirmModal()}
      <IconButton
        aria-label="delete"
        onClick={() => handleClickOpen()} //removeWallet(wallet.id)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};
