import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../shared/api";

export const AdminWallets = () => {
  const user = useSelector((state) => state.user);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };

    api.get(`/api/v1/admin/wallets?_=${Date.now()}`, config).then((res) => {
      // update chats
      setRecords(res.data);
    });
  }, []);

  const renderRecords = () => {
    return records.map((record) => {
      return (
        <Grid xs={12}>
          {record.id} / {record.user_id} / {record.wallet_type} /{" "}
          {record.name_3} / {record.public_address} / {record.balance} /{" "}
          {record.public ? "true" : "false"} / {record.created_at}
        </Grid>
      );
    });
  };

  return (
    <Container>
      <Typography variant="h6">Admin::Wallets</Typography>
      <Grid container spacing={2}>
        {renderRecords()}
      </Grid>
    </Container>
  );
};
