import React from "react";

import Container from "@mui/system/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { BitcoinWallets } from "./bitcoinWallets";
import { EthereumWallets } from "./ethereumWallets";
import { Typography } from "@mui/material";
import { api } from "../shared/api";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateWallets } from "../../store/walletSlice";

export const WalletsIndex = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    refreshWallets();
  }, []);

  const refreshWallets = () => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };
    api.get(`/api/v1/wallets?_=${Date.now()}`, config).then((res) => {
      console.log("hit");
      dispatch(updateWallets(res.data));
    });
  };
  return (
    <Container>
      <Grid
        container
        justify="center"
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid xs={12}>
          <Typography variant="h4">Wallets</Typography>
        </Grid>
        {/* <BitcoinWallets /> */}
        <EthereumWallets />
      </Grid>
    </Container>
  );
};
