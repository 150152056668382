import { createSlice } from '@reduxjs/toolkit'
import _ from 'underscore';

const initialState = {
  'BTC': [],
  'ETH': []
}
/*
const initialState = {
  'BTC' : [
    {
      'name_custom': 'My Bitcoin Wallet',
      'wallet_type': 'BTC',
      'name_3': 'BTC',
      'public_address': '0x000000',
      'public_key': '0x00000',
      'private_key': '0x00000',
      'mnemonic': 'the quick brown fox jumps over the lazy dog',
      'balance': '0',
      'value': '0',
      'default': true,
      'public': true,
      'session': { // this is the record from the server
      }
    },

  ],
  'ETH' : [
    {
      'name_custom': 'My Ethereum Wallet',
      'wallet_type': 'ETH',
      'name_3': 'ETH',
      'public_address': '0x00000',
      'public_key': '0x00000',
      'private_key': '0x00000',
      'mnemonic': 'the quick brown fox jumps over the lazy dog',
      'balance': '0',
      'value': '0',
      'default': true,
      'public': true,
      'session': {
      },
      'tokens': [
        {
          'name_3': 'SLT',
          'wallet_type': 'ERC20',
          'balance': '0',
          'value': '0',
          'session': {
          }
        }
      ]
    }
  ]
}
*/

export const walletSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    addWallet: (state, action) => {
      var wallet_type = action.payload.name_3;
      state[wallet_type].push(action.payload);
    },
    updateWallet: (state, action) => {
      var wallet_type = action.payload.name_3;

      var wallet_idx = state[wallet_type].findIndex((wallet) => wallet.private_key === action.payload.private_key);
      
      if(wallet_idx < 0) {
        state[wallet_type].push(action.payload);
      } else {
        state[wallet_type][wallet_idx] = {...state[wallet_type][wallet_idx], ...action.payload};
      }
    },
    updateWallets: (state, action) => {
      let wallets = action.payload;

      let btc = wallets.filter((wallet) =>  wallet.name_3 === "BTC");
      let eth = wallets.filter((wallet) =>  wallet.name_3 === "ETH");

      state["BTC"] = btc;
      state["ETH"] = eth;

      return state;

      /*
      let imported_wallets = action.payload;
      // get wallet types
      let wallet_types = Object.keys(state);
      // for each wallet type
      let new_wallets = JSON.parse(JSON.stringify(initialState));
      wallet_types.forEach((wallet_type) => {
        let wallets = state[wallet_type];
        // go through list, removing from list
        imported_wallets.forEach((wallet) => {
          if(wallet.name_3 == wallet_type) {
            new_wallets[wallet_type] = wallets.filter(x => x.id === wallet.id) 
            // push into wallet type
            new_wallets[wallet_type].push(wallet);
          }
        });
      });
      state = new_wallets;
      return state;
      */
    },
    deleteWallet: (state, action) => {
      let wallet_id = action.payload.wallet_id;
      let wallet_type = action.payload.wallet_type;
      let found_record = _.findWhere(state[wallet_type], {
        id: wallet_id
      })

      let records = _.without(state[wallet_type], found_record);

      console.log(wallet_id, found_record, records);

      state[wallet_type] = records
    },
    purgeWallets: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const { addWallet, updateWallet, updateWallets, deleteWallet, purgeWallets } = walletSlice.actions

export default walletSlice.reducer