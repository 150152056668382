import React, { useEffect, useState } from "react";

import List from "@mui/material/List";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { TransferMessage } from "./message_types/transferMessage";
import { UserMessage } from "./message_types/userMessage";

import { addMessageToChat, addUserMessageToChat } from "../../../store/chatSlice";
import { decrypt_msg } from "../../../utilities/messages";
import { api } from "../../shared/api";

export const Messages = ({ scrollToBottom }) => {
  const user = useSelector((state) => state.user);
  const chats = useSelector((state) => state.chats);
  const dispatch = useDispatch();

  let { chat_id } = useParams();

  let [firstId, setFirstId] = useState(0);

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    scrollToBottom();
    getFirstKnownMessage();
  }, [chats]);

  useEffect(() => {
    console.log(firstId);
  }, [firstId])

  const getFirstKnownMessage = () => {
    var first_known_id = 0;
    var this_chat = chats[chat_id];

    if(this_chat === undefined) {
      first_known_id = 0;
    } else {
      console.log("chat defined");
      try {
        let this_msgs = this_chat['messages'];
        const keys = Object.keys(this_msgs);
        const firstKey = keys[0];

        first_known_id = this_msgs[firstKey].id;
      } catch (error) {
        console.log("GFKM", error);
        first_known_id = 0;
      }
    }
    if(first_known_id === undefined) {
      first_known_id = 0;
    }

    setFirstId(first_known_id);
  }

  const decryptor = (chat_id, msg) => {
    const re = /^-----BEGIN PGP MESSAGE.*/;
    if (re.test(msg.message)) {
      let encrypted_msg;
      if (msg.created_by !== user.session.id) {
        encrypted_msg = msg.message;
      } else {
        encrypted_msg = msg.sender_message;
      }
      decrypt_msg({ msg: encrypted_msg, user: user })
      .then((decrypted_msg) => {
        let new_msg = { ...msg }; // Use object spread to clone the message
        new_msg.message = decrypted_msg;
        let record = { chat_id: chat_id, msg: new_msg }
        //console.log("record", record);
        dispatch(addMessageToChat(record));
      })
      .catch((error) => {
        console.error("Decryption error:", error);
      });
    }
  }

  const getPreviousMessages = () => {
    console.log(firstId);
    let url = `/api/v1/chats/${chat_id}/previous?last=${firstId}&_=${Date.now()}`;
    console.log("url", url);

    api.get(url, config).then((res) => {
      let new_chat = res.data;
      new_chat.messages.forEach((msg) => {
        decryptor(new_chat.session.id, msg);
      });
    });
  }

  useEffect(() => {
    const handleScrollToTop = () => {
      getPreviousMessages();
    }

    function handleScroll() {
      if (window.scrollY === 0) {
        handleScrollToTop(); // Call your function when user scrolls to the top
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [firstId]);

  const renderMessages = () => {
    if (chat_id in chats) {
      //console.log(chats[chat_id]);
      return chats[chat_id].messages.map((msg, idx) => {
        try {
          if (msg.resource_type === "Transfer") {
          return <TransferMessage msg={msg} />;
          } else {
            // if (msg.message_type === ("User" || "user" || null || undefined || "")) {
            return <UserMessage msg={msg} />;
          }
        } catch (error) {
          console.log("ERR", idx, msg, error);
        }
      });
    }
  };

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "65px",
        }}
      >
        {renderMessages()}
      </List>
    </>
  );
};
