import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "../../store/userSlice";

import Container from "@mui/system/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid
import { api } from "../shared/api";

import { BasicModal } from "../shared/modal";
import { Alert, Typography } from "@mui/material";

export const UserAccount = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [displayName, setDisplayName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [callback, setCallback] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  const updateAccount = () => {
    api
      .post(
        "/api/v1/account",
        {
          display_name: displayName,
          username: username,
          email: email,
          phone_number: phoneNumber,
          callback: callback,
        },
        config
      )
      .then((res) => {
        dispatch(userLogin({ session: res.data, token: user.token }));
        setOpenModal(true);
      });
  };

  useEffect(() => {
    setDisplayName(user.session.display_name);
    setUsername(user.session.username);
    setEmail(user.session.email);
    setPhoneNumber(user.session.phone_number);
  }, [user]);

  return (
    <Container maxWidth="md">
      <BasicModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Success"
        body="Your account information has been updated."
      />
      <Grid
        container
        justify="center"
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid xs={12}>
          <Typography variant="h4">Account</Typography>
        </Grid>
        <Grid xs={12}>
          <TextField
            label="Display name"
            variant="outlined"
            defaultValue={user.session.display_name}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label="Username"
            variant="outlined"
            defaultValue={user.session.username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label="Email"
            variant="outlined"
            defaultValue={user.session.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label="Phone number"
            variant="outlined"
            defaultValue={user.session.phone_number}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <Alert severity="info">The API Callback is where you can send new messages to other outside services. Right now this applies to all chats.</Alert>
        </Grid>
        <Grid xs={12}>
          <TextField
            label="API Callback"
            variant="outlined"
            defaultValue={user.session.callback}
            onChange={(e) => {
              setCallback(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <Button variant="contained" onClick={() => updateAccount()}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
