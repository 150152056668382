import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BasicWalletSelect } from "../../shared/select";

export const CurrencyWalletSelect = ({ setSelectedWallet }) => {
  const [selectedWalletId, setSelectedWalletId] = useState(null);

  const wallets = useSelector((state) => state.wallets);

  useEffect(() => {
    let wallet = wallets["ETH"].find((w) => w.id == selectedWalletId);
    console.log(wallet);
    setSelectedWallet(wallet);
  }, [selectedWalletId]);

  const buildWalletOptions = () => {
    let options = [];
    for (let wallet_type in wallets) {
      wallets[wallet_type].map((wallet) => {
        options.push(wallet);
        return true;
      });
    }
    return options;
  };

  return (
    <BasicWalletSelect
      label="Your Wallets"
      setSelected={setSelectedWalletId}
      selected={selectedWalletId}
      options={buildWalletOptions()}
    />
  );
};
