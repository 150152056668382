import React, {useState} from "react";

import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { LogoutModal } from "./logoutModal";


export const AccountMenu = () => {
  const user = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    handleClose();
    setOpenLogoutModal(true);
  }

  const renderMenu = () => {
    return (
      <>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick} 
          color="inherit"
        >
          @{user.session.username}
        </Button>
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        >
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
        <LogoutModal open={openLogoutModal} setOpen={setOpenLogoutModal} />
      </>
    )

  } 
  return (
    <>
      {renderMenu()}
    </>
  );
}