import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import IconButton from "@mui/material/IconButton";

function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const Download = ({ wallet }) => {
  return (
    <IconButton aria-label="export">
      <FileDownloadIcon
        onClick={() => {
          download(
            wallet.public_address + ".txt",
            wallet.public_key + "\n\n" + wallet.private_key
          );
        }}
      />
    </IconButton>
  );
};
