import React, { useEffect, useState } from "react";

import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

import { useSelector } from "react-redux";
import Avatar from '@mui/material/Avatar';


export const NameBox = ({ account }) => {


  function getContrastColor(hexColor) {
    // Convert the hex color to RGB components
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
  
    // Calculate the relative luminance (brightness) of the color
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // Determine whether to return white or black text color
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }
  
  function stringToColor(str) {
    // Calculate a numerical value from the string
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    // Generate a color using the hash value
    const hexColor = `#${((hash % 0xFFFFFF) + 0x1000000).toString(16).slice(1)}`;
  
    // Get the contrast text color (black or white)
    const contrastColor = getContrastColor(hexColor);
  
    return { background: hexColor, text: contrastColor };
  }

  const render = () => {
    let colors = stringToColor(account);
    let bgColor = colors.background;
    let textColor = colors.text;
    return (
      <Avatar sx={{bgcolor: bgColor, color: textColor, marginRight: 2}}>{account[0]}</Avatar>
    );
  };

  return (
    <>
      {render()}
    </>
  );
};
