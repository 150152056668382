import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ContactsIcon from '@mui/icons-material/Contacts';
import MessageIcon from '@mui/icons-material/Message';
import WalletIcon from '@mui/icons-material/Wallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import AppsIcon from '@mui/icons-material/Apps';

import FeedbackIcon from '@mui/icons-material/Feedback';
import FeedbackModal from '../components/shared/feedbackModal';




import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';


// * Menu
// Contacts
// Chats
// Wallets
// -
// Profile
// Settings

export const SwipeDrawer = ({toggleDrawer, state}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <div style={{textAlign: 'center', margin: 'auto', marginTop: '10px'}}>
            <img src="/logo.png" alt="Salt Logo" height="30px" width="30px" />
          </div>
        </ListItem>
        <ListItem key="Contacts" disablePadding>
          <ListItemButton onClick={()=> navigate("/contacts")}>
            <ListItemIcon>
              <ContactsIcon sx={{color: '#456ac1'}} />
            </ListItemIcon>
            <ListItemText primary="Contacts" />
          </ListItemButton>
        </ListItem>

        <ListItem key="Chats" disablePadding>
          <ListItemButton onClick={()=> navigate("/chats")}>
            <ListItemIcon>
              <MessageIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="Chats" />
          </ListItemButton>
        </ListItem>

        <ListItem key="Wallets" disablePadding>
          <ListItemButton onClick={()=> navigate("/wallets")}>
            <ListItemIcon>
              <WalletIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="Wallets" />
          </ListItemButton>
        </ListItem>

        <ListItem key="Transfers" disablePadding>
          <ListItemButton onClick={()=> navigate("/transfers")}>
            <ListItemIcon>
              <CurrencyExchangeIcon sx={{color: '#456ac1'}} />
            </ListItemIcon>
            <ListItemText primary="Transfers" />
          </ListItemButton>
        </ListItem>

        <ListItem key="Apps" disablePadding>
          <ListItemButton onClick={()=> navigate("/apps")}>
            <ListItemIcon>
              <AppsIcon sx={{color: '#456ac1'}} />
            </ListItemIcon>
            <ListItemText primary="Apps" />
          </ListItemButton>
        </ListItem>

      </List>
      <Divider />
      <List>
        <ListItem key="Profile" disablePadding>
          <ListItemButton onClick={()=> navigate(`/users/${user.session.username}`)}>
            <ListItemIcon>
              <AccountCircleIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
        </ListItem>
        <ListItem key="Account" disablePadding>
          <ListItemButton onClick={()=> navigate("/account")}>
            <ListItemIcon>
              <ManageAccountsIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItemButton>
        </ListItem>

        <ListItem key="ApiKeys" disablePadding>
          <ListItemButton onClick={()=> navigate("/apikeys")}>
            <ListItemIcon>
              <SmartToyIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="API Keys" />
          </ListItemButton>
        </ListItem>

        <ListItem key="Setttings" disablePadding>
          <ListItemButton onClick={()=> navigate("/settings")}>
            <ListItemIcon>
              <KeyIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="Chat Keys" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key="Invites" disablePadding>
          <ListItemButton onClick={()=> navigate("/invites")}>
            <ListItemIcon>
              <EmailIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="Invites" />
          </ListItemButton>
        </ListItem>
        <ListItem key="Feedback" disablePadding>
          <ListItemButton onClick={()=> setFeedbackOpen(true)}>
            <ListItemIcon>
              <FeedbackIcon sx={{color: '#456ac1'}}/>
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="leftDrawer">
        { /* <Button onClick={toggleDrawer("left", true)}>Menu</Button> */ }
        <SwipeableDrawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </React.Fragment>
      <FeedbackModal open={feedbackOpen} setOpen={setFeedbackOpen}/>
    </div>
  );
}