import { HOST } from "../../store/constants.js";
import axios from "axios";

axios.defaults.baseURL = HOST;

// move this to redux and add it to the api as a header when
// the beaer token is present

// axios.defaults.headers.common['Authorization'] = "XXXXXX";
axios.defaults.headers.post["Content-Type"] = "application/json";

export const api = axios.create();
