import { Avatar, Button, Container, TextField, Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";
import { api } from "../shared/api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


export const AppsShow = () => {
  const user = useSelector((state) => state.user);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [number, setNumber] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');

  let { app_id } = useParams();

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    api.get(`/api/v1/apps/${app_id}/`, config).then((res) => {
      const rec = res.data;
      setName(rec.display_name);
      setUsername(rec.username);
      setEmail(rec.email);
      setNumber(rec.phone_number);
      setDescription(rec.bio);
    });
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Typography variant="h5">{name}</Typography>
        <Grid xs={12}>
          <Avatar>{name[0]}</Avatar>
        </Grid>
        <Grid xs={12}>
          {name} (@{username})
        </Grid>
        <Grid xs={12}>
          Email: {email}
        </Grid>
        <Grid xs={12}>
          Phone number: {number}
        </Grid>
        <Grid xs={12}>
          {description}
        </Grid>
        <Grid xs={12}>
          <Button variant="contained">Subscribe</Button>
        </Grid>
      </Grid>
    </Container>
  );
}