import { Avatar, Button, Container, TextField, TextareaAutosize, Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";
import { api } from "../shared/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { generateKeys } from "../../utilities/encrypt";


const textareas = {
  'width': '100%',
}

export const AppsCreate = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const [name, setName] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [description, setDescription] = useState();
  const [webhook, setWebhook] = useState();
  const [password, setPassword] = useState();
  const [link, setLink] = useState();

  const [privateKey, setPrivateKey] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [revocationCert, setRevocationCert] = useState('');
  const [fingerprint, setFingerprint] = useState('');


  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    generateKeys().then((keys) => {
      setPublicKey(keys.public_key);
      setPrivateKey(keys.private_key);
      setRevocationCert(keys.revocation_cert);
      setFingerprint(keys.public_fingerprint);
    });
  }, []);

  const submit = () => {
    let params = {
      email: email,
      display_name: name,
      username: username,
      phone_number: number,
      description: description,
      webhook: webhook,
      password: password,
      link: link,
      public_key: publicKey,
      private_key: privateKey,
      public_fingerprint: fingerprint,
    }
    api.post(`/api/v1/apps/`, params, config).then((res) => {
      const app = res.data;
      navigate(`/apps/${app.id}/`);
    });
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Typography variant="h5">Create a new app</Typography>
        <Grid xs={12}>
          <Avatar>N</Avatar>
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setName(e.target.value)}
            value={name}
            id="outlined-basic" label="App Name" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            id="outlined-basic" label="App Username" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setNumber(e.target.value)}
            value={number}
            type="tel"
            id="outlined-basic" label="Admin Phone Number" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            id="outlined-basic" label="Admin Email" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setLink(e.target.value)}
            value={link}
            type="url"
            id="outlined-basic" label="Website URL" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            id="outlined-basic" label="App Description" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setWebhook(e.target.value)}
            value={webhook}
            type="url"
            id="outlined-basic" label="Webhook URL" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField 
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
            id="outlined-basic" label="App Password" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextareaAutosize
            value={publicKey}
            disabled
            id="outlined-basic" label="Public Key" variant="outlined" />         
        </Grid>
        <Grid xs={12}>
          <TextareaAutosize
            value={privateKey}
            disabled
            id="outlined-basic" label="Private Key" variant="outlined" />         
        </Grid>
        <Grid xs={12}>
          <TextareaAutosize
            value={fingerprint}
            disabled
            id="outlined-basic" label="Public Fingerprint" variant="outlined" />         
        </Grid>
        <Grid xs={12}>
          <Button onClick={submit} variant="contained">Create</Button>
        </Grid>
      </Grid>
    </Container>
  );
}