import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  "Andy Smith": {
    id: 1,
    username: "andysmith",
    email: "andy@smith.com",
    chat_id: 1,
  }
}

export const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    addContact: (state, action) => {
      state[action.payload.display_name] = action.payload;
    },
    deleteContact: (state, action) => {
      delete state[action.payload];
    },
    purgeContacts: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const { addContact, deleteContact, clearContacts, purgeContacts } = contactSlice.actions

export default contactSlice.reducer