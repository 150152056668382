export const fr = {
  translation: {
    Home: {
      hero: {
        about1: 'Salt est une application et une plate-forme de chat de cryptage et de crypto-monnaie intégrées.',
        about2: 'Discutez et envoyez des crypto-monnaies via des messages privés avec n\'importe qui.',
        webapp: 'Application Web'
      },
      downloads: {
        title: 'Téléchargements'
      },
      body: {
        chat1: 'Messages entièrement sécurisés et privés',
        chat2: 'Chiffrement à l\'aide de la cryptographie OpenPGP PKE',
        crypto1: 'Variété de portefeuilles de crypto-monnaie et de jetons',
        crypto2: 'Prend actuellement en charge : Bitcoin, Ethereum, Dogecoin',
        profiles1: 'Comptes vérifiés',
        profiles2: 'Avis et notation',
        api1: 'Construisez n\'importe quel type de bot',
        api2: 'API de développeur pour intégrer tout ce que vous pouvez imaginer'
      }
    }
  }
}