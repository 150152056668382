import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const BasicWalletSelect = ({
  label,
  setSelected,
  selected,
  options,
}) => {
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const renderOptions = () => {
    return options.map((wallet, i) => {
      return (
        <MenuItem value={wallet.id}>
          {wallet.wallet_type}: {wallet.balance} - {wallet.public_address}
        </MenuItem>
      );
    });
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          displayEmpty={true}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={selected}
          onChange={handleChange}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </Box>
  );
};

export const BasicTokenSelect = ({ label, setSelected, selected, options }) => {
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const renderOptions = () => {
    let new_options = options.map((token, i) => {
      return (
        <MenuItem value={token.id}>
          {token.token_name} ({token.token_shortname}): {token.amount}
        </MenuItem>
      );
    });
    new_options.unshift(<MenuItem value={null}>&nbsp;</MenuItem>);
    return new_options;
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={selected}
          onChange={handleChange}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </Box>
  );
};
