import { useState } from "react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { CurrencyModal } from "./currencyModal";

import { IconButton } from "@mui/material";

export const ChatCurrency = ({ chat }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <IconButton color="inherit" onClick={() => setOpenModal(true)}>
        <CurrencyExchangeIcon />
      </IconButton>
      <CurrencyModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        chat={chat}
      />
    </>
  );
};
