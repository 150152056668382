import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useSelector } from "react-redux";

import { api } from "./api";

export default function FeedbackModal({ open, setOpen }) {
  const user = useSelector((state) => state.user);

  const [body, setBody] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const sendFeedback = () => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };
    const params = {
      body: body,
    };
    api.post("/api/v1/feedbacks", params, config).then((res) => {
      setBody("");
      handleClose();
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We'd love to hear from you! Have ideas, comments, concerns,
            feedback, or anything else? Drop us a note here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tell us anything"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setBody(e.target.value)}
            value={body}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={sendFeedback}>Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
