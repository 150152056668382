import { store } from '../store/store'
import { ethers } from "ethers";
import { bitcoin } from './bitcoin';

import { api } from "../components/shared/api"

export const generateBTCWallet = () => {
  const user = store.getState().user;
  console.log("Gen BTC");

  let wallet = bitcoin.generate();
  let params = {
    'name_custom': 'Unnamed',
    'wallet_type': 'BTC',
    'name_3': 'BTC',
    'public_address': wallet.publicAddress,
    'public_key': wallet.publicKey.toString('hex'),
    'private_key': wallet.privateKey.toString('hex'),
    'balance': '0',
    'value': '0',
    'public': false,
    'default': false,
    'backedup': true,
    'session' : {
    }
  }

  const config = {
    headers: { Authorization: `${user.token}` }
  };

  api.post("/api/v1/wallets", params, config).then((results) => {
    let record = {
      'type': "wallets/addWallet", 
      'payload': results.data
    }
    console.log(record);
    store.dispatch(record);
  });

}

export const generateETHWallet = () => {
  const user = store.getState().user;
  console.log("Gen ETH");

  let wallet = ethers.Wallet.createRandom();

  let params = {
    'name_custom': 'Unnamed',
    'wallet_type': 'ETH',
    'name_3': 'ETH',
    'public_address': wallet.address,
    'public_key': wallet.publicKey,
    'private_key': wallet.privateKey,
    'balance': '0',
    'value': '0',
    'public': false,
    'default': false,
    'backedup': true,
    'tokens': [],
    'session' : {
    }
  }

  const config = {
    headers: { Authorization: `${user.token}` }
  };
  console.log(params);
  api.post("/api/v1/wallets", params, config).then((res) => {

    console.log(res.data);
    let record = {
      'type': "wallets/addWallet", 
      'payload': res.data
    }
    console.log(record);
    store.dispatch(record)
  });
}