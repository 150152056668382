import React, { useEffect, useState } from "react";

import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

import { useSelector } from "react-redux";
import { NameBox } from "./NameBox";

export const UserMessage = ({ msg }) => {
  const user = useSelector((state) => state.user);

  const renderMessage = () => {
    return (
      <>
        <ListItem
          alignItems="flex-start"
          sx={{
            backgroundColor:
              msg.user.id === user.session.id ? "#F2F2F2" : "#EEEEFF",
          }}
        >
          <NameBox account={msg.user.display_name} />
          <ListItemText
            primary={`${msg.message}`}
            primaryTypographyProps={{
              textAlign: "left",
              wordBreak: "break-word",
              overflow: "hidden",
            }}
            secondary={new Date(msg.created_at).toLocaleString()}
            secondaryTypographyProps={{
              textAlign: msg.user_id === user.session.id ? "left" : "right",
            }}
          />
        </ListItem>
        <Divider component="li" />
      </>
    );
  };

  return <>{renderMessage()}</>;
};
