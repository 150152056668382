import React, { useState, useEffect } from "react";
import List from "@mui/material/List";

import { api } from "../../shared/api";
//import { useSelector, useDispatch } from 'react-redux';
import { useSelector } from "react-redux";

import { LastMessage } from "./LastMessage";

export const ChatsIndex = () => {
  const user = useSelector((state) => state.user);

  
  // This should get chat and add to state, which should return state
  const [newChats, setNewChats] = useState([]);
  // const chats = useSelector((state) => state.chats);

  useEffect(() => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };

    api.get(`/api/v1/chats?_=${Date.now()}`, config).then((res) => {
      // update chats
      setNewChats(res.data);
    });
  }, []);

  const renderRecords = () => {
    return newChats.map((chat) => {
      return <LastMessage chat={chat} user={user} />
    });
  };

  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {renderRecords()}
      </List>
    </>
  );
};
