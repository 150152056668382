import { createSlice } from '@reduxjs/toolkit'
import _ from 'underscore';

const initialState = []


export const transferSlice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    addTransfer: (state, action) => {
      // should check for existing record and replace, if not push
      state = state.push(action.payload);
    },
    addTransfers: (state, action) => {
      state = _.uniq(state, (x => x.id));
      action.payload.forEach((transfer) => {
        state = _.filter(state, (entry) => {
          return entry.id !== transfer.id
        });
        state.push(transfer);
      })
      state = _.sortBy(state, (x => x.id)).reverse();
      return state;
    },
    updateTransfer: (state, action) => {
    },
    deleteTransfer: (state, action) => {
    },
    purgeTransfers: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const { addTransfer, addTransfers, updateTransfer, deleteTransfer, purgeTransfers } = transferSlice.actions

export default transferSlice.reducer