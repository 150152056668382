export const es = {
  translation: {
    Home: {
      hero: {
        about1: 'Salt es una aplicación y plataforma integrada de cifrado y chat de criptomonedas.',
        about2: 'Chatea y envía criptomonedas a través de mensajes privados con cualquier persona.',
        webapp: 'Aplicación Web',
      },
      downloads: {
        title: 'Descargas',
      },
      body: {
        chat1: 'Mensajes totalmente seguros y privados',
        chat2: 'Cifrado usando criptografía OpenPGP PKE',
        crypto1: 'Variedad de monederos de criptomonedas y fichas',
        crypto2: 'Actualmente admite: Bitcoin, Ethereum, Dogecoin',
        profiles1: 'Cuentas verificadas',
        profiles2: 'Reseñas y calificación',
        api1: 'Construye cualquier tipo de bot',
        api2: 'API de desarrollador para integrar todo lo que puedas imaginar'
      }
    }
  }
}