import './App.css';
import React from 'react'

import { Main } from "./components/main/index"
import { ContactsIndex } from "./components/contacts/index"
import { ContactsNew } from "./components/contacts/new"

import { ChatsIndex } from "./components/chats/index"
import { ChatsShow } from "./components/chats/show"
import { UserAccount } from "./components/user/Account"
import { UserSettings } from "./components/user/settings"
import { ProfileIndex } from './components/profile';
import { TransfersIndex } from './components/transfers/TransferIndex';
import { WalletsIndex } from './components/wallets';
import { Wallet } from './components/wallets/wallet';

import { Login } from "./components/login/index";
import { Signup } from "./components/signup/index";
import { Home } from './components/home/index';
import { InvitesIndex } from './components/invites/InvitesIndex';
import { ApiKeys } from './components/user/ApiKeys';

import { AppsIndex } from './components/apps/AppsIndex';
import { AppsCreate } from './components/apps/AppsCreate';
import { AppsAdmin } from './components/apps/AppsAdmin';
import { AppsShow } from './components/apps/AppsShow';

import { AdminUsers } from './components/admin/AdminUsers';
import { AdminWallets } from './components/admin/AdminWallets';

import { NotificationContainer, NotificationManager } from 'react-notifications';


import './notification.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { ActionCableWebSocket } from './utilities/sockets';

// app -> ACWS({app}) -> app.createConsumer -> app.live -> true
// let acws = {}
// let subscription = {}
// <ActionCableWebSocket acws={acws} subscription={subscription} />

function App() {
  let acws = {};
  let subscription = {};

  return (
    <Router>
      <NotificationContainer/>
      <Main />
      <ActionCableWebSocket acws={acws} subscription={subscription} />
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/signup" element={<Signup/>}/>
        <Route exact path="/contacts" element={<ContactsIndex/>}/>
        <Route exact path="/contacts/new" element={<ContactsNew/>}/>
        <Route exact path="/chats" element={<ChatsIndex/>}/>
        <Route exact path="/chats/:chat_id" element={<ChatsShow/>}/>
        <Route exact path="/account" element={<UserAccount/>}/>
        <Route exact path="/settings" element={<UserSettings/>}/>
        <Route exact path="/apikeys" element={<ApiKeys />}/>
        <Route exact path="/wallets" element={<WalletsIndex/>}/>
        <Route exact path="/wallets/:wallet_type/:wallet_id" element={<Wallet />}/>
        <Route exact path="/transfers" element={<TransfersIndex/>}/>
        <Route exact path="/users/:user_id" element={<ProfileIndex/>}/>
        <Route exact path="/apps" element={<AppsIndex/>}/>
        <Route exact path="/apps/:app_id/admin" element={<AppsAdmin/>}/>
        <Route exact path="/apps/:app_id" element={<AppsShow/>}/>
        <Route exact path="/apps/create" element={<AppsCreate/>}/>
        <Route exact path="/invites" element={<InvitesIndex/>}/>

        <Route exact path="/admin/users" element={<AdminUsers/>}/>
        <Route exact path="/admin/wallets" element={<AdminWallets/>}/>
      </Routes>
    </Router>
  );
}

export default App;