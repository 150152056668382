import React from "react";

import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { EthereumWallet } from "./ethereumWallet";
import { generateETHWallet } from "../../utilities/wallet";
import { Alert } from "@mui/material";

const btnStyle = {
  marginRight: "10pt",
};

const sectionHeaderStyle = {
  fontSize: "16pt",
};

export const EthereumWallets = () => {
  const user = useSelector((state) => state.user);
  const wallets = useSelector((state) => state.wallets);

  const renderHeader = () => {
    return (
      <Grid
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Grid>
          <Avatar>E</Avatar>
        </Grid>
        <Grid>
          <Typography noWrap sx={sectionHeaderStyle}>
            Ethereum
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={btnStyle}
            onClick={generateETHWallet}
          >
            Create
          </Button>
          {/*
          <Button variant="contained"
            startIcon={<PublishIcon />}
          >Import</Button>
          */}
        </Grid>
      </Grid>
    );
  };

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Balance</TableCell>
          <TableCell>Value</TableCell>
          <TableCell>Actions</TableCell>
          <TableCell>Saved</TableCell>
          <TableCell>Public</TableCell> {/* Can others see it. */}
          <TableCell>Default</TableCell> {/* is the default being sent to */}
        </TableRow>
      </TableHead>
    );
  };

  const renderWallets = () => {
    let records = wallets["ETH"].map((wallet) => {
      return <EthereumWallet wallet={wallet} user={user} />;
    });

    if(records.length > 0) {
      return records;
    }
  };

  const renderAlert = () => {
    if(wallets["ETH"].length === 0) {
      return (
        <Alert severity="info">No Ethereum wallets added yet.</Alert>
      )
    }
  }

  return (
    <Grid xs={12}>
      {renderHeader()}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          {renderTableHead()}
          <TableBody>{renderWallets()}</TableBody>
        </Table>
      </TableContainer>
      {renderAlert()}
    </Grid>
  );
};
