import React, { useEffect } from "react";
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { purgeUser } from "../store/userSlice";
import { purgeChats } from "../store/chatSlice";
import { purgeContacts } from "../store/contactSlice";
import { purgeWallets } from "../store/walletSlice";
import { purgeTransfers } from "../store/transferSlice";

export const LogoutModal = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    setOpen(false);
    dispatch(purgeChats());
    dispatch(purgeUser());
    dispatch(purgeContacts());
    dispatch(purgeWallets());
    dispatch(purgeTransfers());
    navigate("/");
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wallets that weren't <u>BACKEDUP</u> will be <u>LOST</u>!!<br/><br/>
            Be safe: <br/>
            <ul>
              <li>manually backup your data</li>
              <li>or back up your keys on our server.</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Disagree</Button>
          <Button onClick={logout}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}