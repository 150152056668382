import React, {useState} from "react";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
//import ListItemAvatar from '@mui/material/ListItemAvatar';
//import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { decrypt_msg } from "../../../utilities/messages";
import { useEffect } from "react";
import { useSelector } from "react-redux";


export const LastMessage = ({chat, user}) => {
  const navigate = useNavigate();
  const [last, setLast] = useState('');
  const chats = useSelector((state) => state.chats);

  const getLastMessage2 = async (newChat) => {
    // sent to us
    let new_message = newChat.recent_message.message;
    if(newChat.recent_message.message.created_by !== user.session.id) {
      // check to see if in local cache
      // if not, decrypt and display
      return await decrypt_msg({msg: new_message.message, user: user});
    } else {
      return await newChat.recent_message.message.message;
    }
  }

  const getLastMessage = async (newChat) => {
    let my_chat = chats[newChat.session.id]
    let messages = my_chat.messages;
    let last_msg = messages[messages.length-1].message;
    return await last_msg; 
  }

  useEffect(() => {
    if("message" in chat.recent_message) {
      getLastMessage(chat).then((m) => { setLast(m); });
    } else {
      setLast("No messages yet");
    }
  }, [chats]);

  return (
    <>
      <ListItem
        alignItems="flex-start"
        onClick={() => {
          navigate(`/chats/${chat.session.id}`);
        }}
      >
        <ListItemText
          primary={chat.session.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {"user" in chat.recent_message
                  ? chat.recent_message.user.display_name
                  : "No chat yet"}
              </Typography>
              —{" "}
              { last }
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider component="li" />
    </>
  );
}