import React from 'react';
import ActionCable from 'action-cable-react-jwt';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import chatSlice, { addUserMessageToChat } from '../store/chatSlice';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { decrypt } from './encrypt';

export const ActionCableWebSocket = ({acws, subscription}) => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch();

  useEffect(() => {
    if(user.token) {
      createSocket();
    }
  }, [user.token])

  //console.log(window.location.protocol);

  const createSocket = () => {
    // get your JWT token
    // this is an example using localStorage

    let protocol = window.location.protocol;
    if(protocol.toLowerCase().includes("https")) {
      protocol = "wss";
    } else {
      protocol = "ws";
    }

    //console.log(window.location.port);
    let port = window.location.port;
    if (process.env.NODE_ENV === 'development') {
      port = ":4002"
    } else if (process.env.NODE_ENV === 'production') {
      if(port.length === 0) {
        port = '';
      } else {
        port = ':' + port;
      }
    }

    acws.cable = ActionCable.createConsumer(protocol + '://'+window.location.hostname+port+'/cable', user.token.split(" ")[1])
    subscribe();
  }

  const translate = (data) => {
    if ('tx_id' in data) {
      // this belongs in the 'transfers' slice
      // and should be associated with the transfers in a wallet
    } else if ('message_type' in data) {
      // this belongs in a chat
      if (data.message_type === "Transfer") {
          // transfer messages are unencrypted for now
          console.log("addTransferMessageToChat");
          dispatch(addUserMessageToChat(data));
      } else if (data.message_type === "User") {

        // I sent this
        let message = data.message;
        if(data.user.id === user.session.id) {
          message = data.sender_message;
        }
        decrypt({
          encrypted_message: message,
          receiver_privateKey: user.PKEkeys.private_key,
        })
        .then((decrypted_msg) => {
          let unenc_data = data;
          unenc_data.message = decrypted_msg;
          console.log("addUserMessageToChat");
          dispatch(addUserMessageToChat(unenc_data));
          //console.log(unenc_data);
          NotificationManager.info(
            <>
              {unenc_data.user.display_name}: {unenc_data.message}
            </>
          );
          return true;
        })
        .catch((err) => {
          console.log("err", data.message, user.PKEkeys.private_key);
        });
      }
    }
    /*
    decrypt({
      encrypted_message: data.message,
      receiver_privateKey: user.PKEkeys.private_key,
    })
    .then((decrypted_msg) => {
      let unencrypted_data = data;
      unencrypted_data.message = decrypted_msg;
      dispatch(addUserMessageToChat(unencrypted_data));
      return true;
    })
    .catch((err) => {
      console.log("err", data.message, user.PKEkeys.private_key);
    });
    */
  }

  const subscribe = () => {
    let channel = "UserChannel";
    subscription = acws.cable.subscriptions.create({channel: channel}, {
      connected: () => { console.log("cable: connected") },
      disconnected: () => { console.log("cable disconnected")},
      received: (data) => {
        //console.log("socket rcvd", data.message, user.PKEkeys.private_key);
        translate(data);
      }
    })
  }

  return (
    <>
    </>
  )
}