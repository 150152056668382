import {
  Button,
  ButtonGroup,
  CssBaseline,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../shared/api";

export const InvitesIndex = () => {
  const user = useSelector((state) => state.user);
  const [records, setRecords] = useState([]);
  const [email, setEmail] = useState("");

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    updateInvites();
  }, []);

  const updateInvites = () => {
    api.get(`/api/v1/invites?_=${Date.now()}`, config).then((res) => {
      setRecords(res.data);
    });
  };

  const createInvite = () => {
    let params = {
      email: email,
    };
    api.post(`/api/v1/invites?_=${Date.now()}`, params, config).then((res) => {
      updateInvites();
    });
  };

  const buildInvites = () => {
    return records.map((record) => {
      return (
        <Grid xs={12}>
          {record.code} {record.email} delete
        </Grid>
      );
    });
  };

  return (
    <>
      <CssBaseline />
      <Container>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant="h4">Invites</Typography>
          </Grid>
          <Grid xs={12}>During the beta, we are limiting users.</Grid>
          <Grid xs={12}>
            You have <u>2</u> total invites. Only users with this email can
            create an account.
          </Grid>
          <Grid xs={12} sx={{ marginBottom: 4 }}>
            <ButtonGroup variant="contained">
              <TextField
                id="filled-basic"
                label="Email"
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button onClick={() => createInvite()}>Send Invite</Button>
            </ButtonGroup>
          </Grid>
          <Grid xs={12}>
            {records.length > 0 ? "Invite List" : ""}
            {buildInvites()}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
