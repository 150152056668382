import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import Link from "@mui/material/Link";

import { useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";

import Container from "@mui/system/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Alert, AlertTitle } from "@mui/material";

export const Home = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  /*
  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);
  */

  const renderHero = () => {
    return (
      <Grid
        sx={{
          backgroundColor: "#005fdb",
          paddingBottom: 5,
        }}
        xs={12}
      >
        <Container maxWidth="md" sx={{ color: "white" }}>
          <Grid
            container
            justify="center"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ paddingTop: 2 }}
          >
            <img
              src="/logo.png"
              alt="Salt Logo"
              height="50px"
              width="50px"
              style={{ position: "relative", top: "30px" }}
            />
            <Grid>
              <h1>
                Salt&nbsp; 
                <span style={{fontSize: 18}}>(beta)
                </span>
              </h1>
              <h3>Secure Crypto Chat</h3>
              <p>
                {t("Home.hero.about1")}
                <br />
                {t("Home.hero.about2")}
              </p>
              <div style={{ color: "#067cfa", marginBottom: "9px" }}>
                {t("Home.hero.webapp")}
              </div>
              <Button
                variant="contained"
                sx={{ marginRight: "5px", backgroundColor: "#009e08" }}
                onClick={() => navigate("/signup")}
              >
                Signup
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: "white", color: "black" }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    );
  };

  const renderDownload = () => {
    return (
      <Grid
        sx={{
          backgroundColor: "#005fdb",
          paddingBottom: 5,
          /* backgroundImage: 'url("bg.svg")', */
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="md" sx={{ color: "white" }}>
          <Grid sx={{ textAlign: "center", color: "#067cfa" }}>
            {t("Home.downloads.title")}
          </Grid>
          <Grid
            container
            justify="center"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ paddingTop: 2, textAlign: "center" }}
          >
            <Grid xs={3}>
              <Link color="#FFF" underline="hover" href="https://github.com/0000F8/salt-releases/releases/">
                <Button variant="outlined" sx={{borderColor: '#FFF', color: '#FFF'}}>
                    Mac
                </Button>
              </Link>
            </Grid>
            <Grid xs={3}>
              <Button variant="outlined">
                iPhone
              </Button>
            </Grid>
            <Grid xs={3}>
              <Button variant="outlined">
                PC
              </Button>
            </Grid>
            <Grid xs={3}>
              <Button variant="outlined">
                Android
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    );
  };

  const renderBody = () => {
    return (
      <Container maxWidth="md" sx={{ paddingTop: 5, paddingBottom: 7 }}>
        <Grid
          container
          justify="center"
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid xs={12} md={6}>
            <h3>💬 Chat</h3>
            <p>
              <ul>
                <li>{t("Home.body.chat1")}</li>
                <li>{t("Home.body.chat2")}</li>
              </ul>
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <h3>💰 Crypto</h3>
            <p>
              <ul>
                <li>{t("Home.body.crypto1")}</li>
                <li>{t("Home.body.crypto2")}</li>
              </ul>
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <h3>👤 Profiles</h3>
            <p>
              <ul>
                <li>{t("Home.body.profiles1")}</li>
                <li>{t("Home.body.profiles2")}</li>
              </ul>
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <h3>🤖 APIs & Bots</h3>
            <p>
              <ul>
                <li>{t("Home.body.api1")}</li>
                <li>{t("Home.body.api2")}</li>
              </ul>
            </p>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderFooter = () => {
    return (
      <Grid
        sx={{ backgroundColor: "#0047a2", paddingBottom: 5, paddingTop: 3 }}
        xs={12}
      >
        <Container maxWidth="md" sx={{ color: "white" }}>
          <Grid
            container
            justify="center"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ paddingTop: 2 }}
          >
            <Grid xs={12} style={{ textAlign: "right", color: "lightskyblue" }}>
              docs &#183; faq &#183; terms &#183; contact &#183; © 2022 Salt.
              All rights reserved.
            </Grid>
          </Grid>
        </Container>
      </Grid>
    );
  };


  const renderBeta = () => {
    return (
      <Container maxWidth="md">
        <Alert severity="warning">
          <AlertTitle>Beta Caution</AlertTitle>
          <ul>
            <li>Cryptocurrency transactions work on testnets</li>
            <li>The software is under constant development</li>
            <li>Data may not be retained</li>
          </ul>
        </Alert>
      </Container>
    )
  }

  return (
    <>
      <CssBaseline />

      {renderHero()}
      {renderDownload()}
      {renderBody()}
      {renderBeta()}
      {renderFooter()}
    </>
  );
};
