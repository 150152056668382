import { Button } from "@mui/material"
import { useSelector } from "react-redux";
import { api } from "../shared/api";

export const TransferRefreshButton = ({transfer}) => {

  const user = useSelector((state) => state.user);

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  const refresh = () => {
    api.get(`/api/v1/transfers/${transfer.id}/info?_=${Date.now()}`, config).then((res) => {
      console.log(res.data);
    })
  }

  return (
    <Button onClick={refresh}>Refresh</Button>
  )
}