import { Avatar, Button, Container, TextField, Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";
import { api } from "../shared/api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


export const AppsAdmin = () => {
  const user = useSelector((state) => state.user);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [number, setNumber] = useState("");
  const [description, setDescription] = useState("");
  const [webhook, setWebhook] = useState("");
  const [email, setEmail] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  let { app_id } = useParams();

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    api.get(`/api/v1/apps/${app_id}/admin`, config).then((res) => {
      const rec = res.data;
      setName(rec.display_name);
      setUsername(rec.username);
      setEmail(rec.email);
      setNumber(rec.phone_number);
      setDescription(rec.bio);
      setWebhook(rec.callback);
      setApiKey(rec.apikey.api_key);
      setPublicKey(rec.userkeys.public_key);
      setPrivateKey(rec.userkeys.private_key);
    });
  }, []);

  const updateRecord = () => {
    const params = {
      phone_number: number,
      display_name: name,
      username: username,
      email: email,
      bio: description,
      webhook: webhook
    }
    api.put(`/api/v1/apps/${app_id}/admin_update`, params, config).then((res) => {
      const rec = res.data;
      setName(rec.display_name);
      setUsername(rec.username);
      setEmail(rec.email);
      setNumber(rec.phone_number);
      setDescription(rec.bio);
      setWebhook(rec.callback);
      setApiKey(rec.apikey.api_key);
      setPublicKey(rec.userkeys.public_key);
      setPrivateKey(rec.userkeys.private_key);
    });
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Typography variant="h5">{name}</Typography>
        <Grid xs={12}>
          <Avatar>{name[0]}</Avatar>
        </Grid>
        <Grid xs={12}>
          <TextField value={name} 
            onChange={(e) => setName(e.target.value)}
          id="outlined-basic" label="App Name" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField value={username} 
            onChange={(e) => setUsername(e.target.value)}
          id="outlined-basic" label="App Username" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField value={email} 
            onChange={(e) => setEmail(e.target.value)}
          id="outlined-basic" label="Admin Email" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField value={number} 
            onChange={(e) => setNumber(e.target.value)}
          id="outlined-basic" label="Admin Phone Number" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField value={description} 
            onChange={(e) => setDescription(e.target.value)}
          id="outlined-basic" label="App Description" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField value={webhook} 
            onChange={(e) => setWebhook(e.target.value)}
          id="outlined-basic" label="Webhook URL" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField disable value={apiKey} 
          id="outlined-basic" label="API Key" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField disable value={publicKey} 
          id="outlined-basic" label="Public Key" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <TextField disable value={privateKey} 
          id="outlined-basic" label="Private Key" variant="outlined" />
        </Grid>
        <Grid xs={12}>
          <Button onClick={updateRecord} variant="contained">Submit</Button>
        </Grid>
      </Grid>
    </Container>
  );
}