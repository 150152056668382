import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";

import { addUserMessageToChat } from "../../store/chatSlice";

import { api } from "../shared/api";

import { encrypt } from "../../utilities/encrypt";
import { ChatCurrency } from "./currency/chatcurrency";
import { ChatInfo } from "./chatinfo";
import _ from "underscore";

/*
const StyledFab = styled(Fab)({
  zIndex: 1,
  left: 0,
  top: -30,
  right: 0,
  margin: '0 auto',
});
*/

const Send = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    //  [theme.breakpoints.up('md')]: {
    //    width: '20ch',
    //  },
  },
}));

export const ChatBox = ({ chat }) => {
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state.user);
  const chats = useSelector((state) => state.chats);
  const dispatch = useDispatch();

  let { chat_id } = useParams();

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  const processMessage = (e) => {
    setMessage(e.target.value);
  };

  const getOtherUser = () => {
    let receivers = [];
    let chat = chats[chat_id];
    // console.log({chats, chat_id, chat});
    // console.log(chats);
    chat.session.users.forEach((u) => {
      if (u.id !== user.session.id) {
        receivers.push(u);
      }
    });
    // console.log("getOtherUser, receivers", receivers);
    return receivers;
  };

  const sendMessage = () => {
    // send encrypted
    let receiver = getOtherUser()[0];
    // console.log("receiver", receiver);

    encrypt({
      plain_message: message,
      receiver_publicKey: receiver.public_key,
    }).then((encrypted_message) => {
      // console.log(receiver, message, encrypted_message);
      encrypt({
        plain_message: message,
        receiver_publicKey: user.PKEkeys.public_key,
      }).then((sender_encrypted_message) => {
        api
          .post(
            "/api/v1/messages",
            { 
              chat_id: chat_id, 
              message: encrypted_message,
              sender_message: sender_encrypted_message
            },
            config
          )
          .then((res) => {
            //dispatch(addUserMessageToChat({user: user, chat_id: chat_id, message: message}));
            // overwrites encrypted data with the local clear plain text version
            res.data.message = message;
            console.log(res.data);
            dispatch(addUserMessageToChat(res.data));
            console.log("addUserMessageToChat");
            //console.log("message sent");
          });
        setMessage("");
      })
    });
  };

  const processReturn = (e) => {
    if (e.which === 13) {
      sendMessage();
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <Send>
            <StyledInputBase
              value={message}
              placeholder="Chat here"
              inputProps={{ "aria-label": "chatinput" }}
              onChange={(e) => {
                processMessage(e);
              }}
              onKeyDown={(e) => {
                processReturn(e);
              }}
              fullWidth
            />
          </Send>
          <IconButton color="inherit" onClick={sendMessage}>
            <SendIcon />
          </IconButton>
          <ChatCurrency chat={chat} />
          <ChatInfo chat={chat} />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
