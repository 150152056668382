import React, { useState } from "react";

//import { HOST } from '../../store/constants.js';
import { api } from "../shared/api";

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

//import Card from '@mui/material/Card';
import Container from "@mui/system/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Alert, Tooltip } from "@mui/material";

import { SaltSubHeader } from "../../layouts/saltSubHeader";

import { userLogin } from "../../store/userSlice";

// We need to generate keys for first time users

import { generateKeys } from "../../utilities/encrypt";
import { updateKeys } from "../../store/userSlice";

export const Signup = () => {
  const user = useSelector((state) => state.user);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let config = {}
  /*
    headers: { Authorization: `${user.token}` },
  };
  */

  const submitAuth = () => {
    let params = {
      user: {
        display_name: name,
        username: username,
        phone_number: phone,
        email: email,
        password: password1,
      },
    };

    api.post(`/auth/?_=${Date.now()}`, params).then((res) => {
      config = {
        headers: { Authorization: res.headers.get("Authorization") }
      };
      let auth_headers = res.headers.get("Authorization");
      dispatch(
        userLogin({
          session: res.data,
          token: auth_headers
        })
      );
      genNewKeys(res.headers);
    }).catch((error) => {
      console.log(error);
      setErrors(error.response.data.status.message);
    });
  };

  const genNewKeys = () => {
    generateKeys().then((PKEkeys) => {
      console.log(PKEkeys);
      api.post(`/api/v1/settings/keys?_=${Date.now()}`, PKEkeys, config).then((res) => {
        dispatch(updateKeys(PKEkeys));
        navigate("/chats");
      });
    });
  }

  const renderErrors = () => {
    if(errors !== null) {
      return (
        <Alert severity="error">{errors}</Alert>
      )
    }
  }

  const renderBody = () => {
    return (
      <>
        <SaltSubHeader />
        <Container maxWidth="xs">
          <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <h1>Signup</h1>
            </Grid>
            <Grid xs={12}>
              { renderErrors() }
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Username"
                variant="outlined"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Phone number"
                variant="outlined"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                onChange={(e) => {
                  setPassword1(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <Button variant="contained" onClick={() => submitAuth()}>
                Create Account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  return (
    <>
      Sorry not available.
    </>
  );
};
