import { useEffect, useState } from "react";
import { BasicWalletSelect } from "../../shared/select";

export const CurrencyReceiverSelect = ({setSelectedReceiverWallet, wallets}) => {
  const [selectedWalletId, setSelectedWalletId] = useState(null);

  useEffect(() => {
    let wallet = wallets.find((w) => w.id === selectedWalletId);
    console.log(wallet);
    setSelectedReceiverWallet(wallet);
  }, [selectedWalletId]);

  const buildWalletOptions = () => {
    let options = [];
    wallets.map((wallet) => {
      options.push(wallet);
      return true;
    });
    return options;
  };

  return (
    <BasicWalletSelect
      label="Receiver Wallets"
      setSelected={setSelectedWalletId}
      selected={selectedWalletId}
      options={buildWalletOptions()}
    />
  )
}