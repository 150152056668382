import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import QRCode from "react-qr-code";

export const SimpleAccordion = ({ records }) => {
  const createAccordionSlice = ({ title, body }) => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <QRCode value={body} />
          </Typography>
          <Typography>
            <span style={{ whiteSpace: "pre-line", fontSize: "8pt" }}>
              {body}
            </span>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderAccordion = () => {
    return records.map((record) => {
      return createAccordionSlice(record);
    });
  };
  return <>{renderAccordion()}</>;
};
