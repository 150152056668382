import { Typography } from "@mui/material";
import Container from "@mui/material/Container";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const Wallet = () => {
  let { wallet_id, wallet_type } = useParams();
  const wallets = useSelector((state) => state.wallets);
  const wallet = wallets[wallet_type].find((w) => w.id === parseInt(wallet_id));

  return (
    <Container>
      <Typography variant="h6">{wallet.public_address}</Typography>
      <Typography>Tokens</Typography>
      <ul>
        <li>SALT: 0.00001</li>
        <li>ETC: 0.0002</li>
      </ul>
      <Typography>Transfers</Typography>
    </Container>
  );
};
