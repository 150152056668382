import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/system/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


export const SaltSubHeader = () => {
  return(
    <>
      <CssBaseline/>
      <Grid sx={{backgroundColor: '#005fdb', paddingBottom: 0}} xs={12}>
        <Container maxWidth='md' sx={{color: 'white'}}>
          <Grid container justify="center" spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{paddingTop: 2}}>
            <img src="/logo.png" alt="Salt Logo" height="50px" width="50px" style={{position: 'relative', top: '30px'}}/>
            <Grid>
              <h1>Salt</h1>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}