import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import userReducer from "./userSlice";
import chatReducer from "./chatSlice";
import contactReducer from "./contactSlice";
import walletReducer from "./walletSlice";
import transferReducer from "./transferSlice";

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  user: userReducer,
  chats: chatReducer,
  contacts: contactReducer,
  wallets: walletReducer,
  transfers: transferReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  devTools: true,
  middleware: [thunk]
})

export const persistor = persistStore(store)