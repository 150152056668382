import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import { AuthStatus } from './authstatus';

import { Offline, Online } from "react-detect-offline";

export const HeaderBar = ({toggleDrawer, state}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{backgroundColor: '#005fdb'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Salt
          </Typography>
          <AuthStatus />
        </Toolbar>
        <Offline>
          <div style={{
            'backgroundColor': '#ff5c5c',
            'color': '#FFF',
            'textAlign': 'center',
            'fontSize': '13pt',
            'paddingBottom': '5pt',
            'borderBottom': '1px solid rgba(255, 255, 255, 0.5)'
            }}>
            <OfflineBoltIcon sx={{ position: 'relative', top: '5px', marginRight: '10px' }}/>
            You are offline.
          </div>
        </Offline>
      </AppBar>
      <Toolbar />
    </Box>
  );
}