import { Chip, Container } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { api } from "../shared/api";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import QRCode from "react-qr-code";

export const ProfileIndex = () => {
  const [user, setUser] = useState({});
  let { user_id } = useParams();

  useEffect(() => {
    api.get(`/api/v1/users/${user_id}?_=${Date.now()}`).then((res) => {
      setUser(res.data);
    });
  }, []);

  const formatFingerprint = (fingerprint) => {
    let fp = fingerprint
      .split(/(..)/g)
      .filter((s) => s)
      .join(":");
    console.log(fp);
    return fp;
  };

  const renderProfile = () => {
    if ("display_name" in user) {
      return (
        <>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <QRCode size={128} value={`salt://${user.public_fingerprint}`} />
          </div>
          <h1>{user.display_name}</h1>
          <h2>@{user.username}</h2>
          <Chip variant="filled" label={user.account_type} />
          <div
            style={{
              fontWeight: "300",
              fontVariantCaps: "all-petite-caps",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "7px",
              }}
            >
              <FingerprintIcon />
            </div>
            <div>
              <div>Fingerprint</div>
              {user.public_fingerprint}
            </div>
          </div>
        </>
      );
    }
  };

  return <Container>{renderProfile()}</Container>;
};
