import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import TextField from "@mui/material/TextField";
import Container from "@mui/system/Container";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import { api } from "../shared/api";
import { addContact } from "../../store/contactSlice";
import { useNavigate } from "react-router-dom";

export const ContactsNew = () => {
  const [records, setRecords] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fingerprint, setFingerprint] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  const submitSearch = () => {
    api
      .get("/api/v1/search/contacts", {
        params: {
          username: username,
          email: email,
          phone_number: phone,
          public_fingerprint: fingerprint,
        },
        ...config,
      })
      .then((res) => {
        setRecords(res.data);
      });
  };

  const remoteAddContact = (contact_id) => {
    api
      .post("/api/v1/contacts/", { contact_id: contact_id }, config)
      .then((res) => {
        console.log("addContact");
        dispatch(addContact(res.data));
        navigate("/contacts");
      });
  };

  const searchField = () => {
    return (
      <Container>
        <Box textAlign="center">
          <List>
            <ListItem>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Fingerprint"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setFingerprint(e.target.value);
                }}
              />
            </ListItem>
          </List>
          <Button
            size="large"
            variant="contained"
            startIcon={<PersonAddIcon />}
            fullWidth
            onClick={submitSearch}
          >
            Search
          </Button>
        </Box>
      </Container>
    );
  };

  const searchResults = () => {
    return records.map((record) => {
      return (
        <>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="add"
                onClick={() => {
                  remoteAddContact(record.id);
                }}
              >
                <AddIcon />
              </IconButton>
            }
          >
            <ListItemText>
              {record.display_name} (@{record.username})
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      );
    });
  };

  const searchResultsContainer = () => {
    if (records.length > 0) {
      return (
        <>
          <h2>Search Results</h2>
          <Divider />
          <List>{searchResults()}</List>
        </>
      );
    }
  };

  return (
    <Container>
      <h1>Contact Search</h1>
      {searchField()}
      {searchResultsContainer()}
    </Container>
  );
};
