import { createSlice } from '@reduxjs/toolkit'
import _ from 'underscore';

/*
  <chat_id>: {
    session: {
      users: [{}, {}]
    },
    messages: [{
      encrypted: true || false,
    }, {}]
  }
*/

const initialState = {}

/*
  {
  // Chat hash by chat-id
  1: {
    session: {
      id: 1,
      name: "Unnamed room",
      users: [
        {
          id: 1,
          username: 'test1',
          display_name: 'Test Account 1',
          email: 'test1@test.com',
          public_key: 'zzzzzzz',
        },
      ]
    },
    messages: [{
      message_id: 1,
      message: "Lorem ipsum dolorem. Lorem ipsum dolorem. Lorem ipsum dolorem. Lorem ipsum dolorem.",
      message_type: "user",
      version: 1,
      encrypted: false,
      user: {
        id: 1,
        email: 'test1@test.com',
        username: 'test1',
        display_name: 'Test Account 1'
      },
      created_at: "10/31/99 00:00:01",
    }],
  }
}

*/

// this was used in testing
/*
const formatUserMessage = (state, user, message, chat_id) => {
  let currentDate = new Date();
  return {
    message_id: Object.keys(state).length,
    message: message,
    message_type: "user",
    version: 1,
    user: {
      id: user.session.id,
      email: user.session.email,
      username: user.session.username,
      display_name: user.session.display_name,
    },
    created_at: currentDate.toLocaleFormat('%d-%b-%Y %I:%M:%S %p') 
  }
}
*/

export const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    addChat: (state, action) => {
      console.log("rdx_addChat", action.payload);
      let p = action.payload;
      // action.payload.messages = [];
      state[p.session.id] = action.payload;
    },
    addMessageToChat: (state, action) => {
      let p = action.payload;
      let chat_id = p.chat_id;
      let msg = p.msg;
      let idx = state[chat_id].messages.findIndex((m) => m.id === msg.id)
      try {
        //state[chat_id] = _.extend(_.findIndex(state[chat_id], { message: p.msg.id }), msg);
        state[chat_id].messages[idx].message = msg.message;
      } catch(error) {
        const chat = state[chat_id];
        if (chat) {
          chat.messages.push(msg);
          chat.messages.sort((a, b) => a.id - b.id);
        }
      }
    },
    addUserMessageToChat: (state, action) => {
      console.log("rdx_addUserMessageToChat", action.payload);
      // if the chat id in state then push on chats hash
      let p = action.payload;
      //let formattedMessage = formatUserMessage(state, p.user, p.message, p.chat_id)
      let msg = action.payload; //formatUserMessage(state, p.user, p.message, p.chat_id)

      // check if the chat_id exists, if not, create it and add
      if(p.chat_id in state) {
        let chat = state[p.chat_id];
        // if id found in chat, replace, otherwise add it to the end
        // because in the future we will not integer based IDs
        // console.log("m.id === msg.message_id", chat.map((m) => m.message_id), msg.message_id);

        if(chat.messages.find((m) => { return m.message_id === msg.message_id })) {
          //console.log("chat message found, passing"); 
        } else {
          //console.log("chat message not found, adding");
          chat.messages.push(msg);
          state[p.chat_id] = chat;
        }
      } else {
        // if the chat id not in hash, create it
        //console.log("chat not found, adding message");
        state[p.chat_id] = [msg]; 
      }
    },
    clearChat: (state, action) => {
      state.chats[action.payload.chat_id] = []; 
    },
    removeChat: (state, action) => {
    },
    clearMessage: (state, action) => {
      delete state[action.payload];
    },
    purgeChats: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const { 
  addChat, 
  addUserMessageToChat, 
  addMessageToChat, 
  clearChats, 
  clearChat, 
  clearMessage, 
  purgeChats 
} = chatSlice.actions

export default chatSlice.reducer