import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../shared/api";

export const AdminUsers = () => {
  const user = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };

    api.get(`/api/v1/admin/users?_=${Date.now()}`, config).then((res) => {
      // update chats
      setUsers(res.data);
    });
  }, []);

  const renderUsers = () => {
    return users.map((usr) => {
      return (
        <Grid xs={12}>
          {usr.id} /{usr.username} /{usr.display_name} /{usr.email} /
          {usr.phone_number} /{usr.created_at}
        </Grid>
      );
    });
  };

  return (
    <Container>
      <Typography variant="h6">Admin::Users</Typography>
      <Grid container spacing={2}>
        {renderUsers()}
      </Grid>
    </Container>
  );
};
