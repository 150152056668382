import React, { useEffect, useState } from "react";
import { HeaderBar } from "../../layouts/headerbar";
import { SwipeDrawer } from "../../layouts/swipedrawer";
import { useLocation } from "react-router-dom";

export const Main = () => {
  let location = useLocation();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const renderNavigation = () => {
    if (
      /(^\/$|^\/about$|^\/signup$|^\/login$)/i.test(location.pathname) === false
    ) {
      return (
        <>
          <HeaderBar toggleDrawer={toggleDrawer} state={state} />
          <SwipeDrawer toggleDrawer={toggleDrawer} state={state} />
        </>
      );
    }
  };

  return <>{renderNavigation()}</>;
};
