import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loggedIn: false,
  session: {},
  token: null,
  PKEkeys: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogin: (state, action) => {
      state.loggedIn = true;
      state.session = action.payload.session;
      state.token = action.payload.token;
    },
    updateKeys: (state, action) => {
      state.PKEkeys = action.payload;
    },
    purgeUser: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { userLogin, userLogout, updateKeys, purgeUser } = userSlice.actions

export default userSlice.reducer