import React, { useEffect, useState } from "react";

import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const TransferMessage = ({ msg }) => {
  const user = useSelector((state) => state.user);

  const renderTransfer = () => {
    return (
      <>
        <Typography>💸 <Link to={`/transfers/${msg.resource_id}`}>Transfer from {msg.user.display_name}</Link></Typography>
        <Typography>Note: {msg.message}</Typography>
      </>
    );
  };

  const renderMessage = () => {
    return (
      <>
        <ListItem
          alignItems="flex-start"
          sx={{
            backgroundColor:
              msg.user.id === user.session.id ? "#F2F2F2" : "#EEEEFF",
          }}
        >
          <ListItemText
            primary={renderTransfer()}
            primaryTypographyProps={{ textAlign: "center" }}
            secondary={new Date(msg.created_at).toLocaleString()}
            secondaryTypographyProps={{
              textAlign: msg.user_id === user.session.id ? "left" : "right",
            }}
          />
        </ListItem>
        <Divider component="li" />
      </>
    );
  };

  return <>{renderMessage()}</>;
};
