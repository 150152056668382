import React, { useEffect, useRef, useState } from "react";
//import { Routes, Route, useParams } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ChatBox } from "./chatbox";

import { Messages } from "./messages/messages";
import { api } from "../shared/api";

import { addMessageToChat } from "../../store/chatSlice";
import { addChat } from "../../store/chatSlice";
import { decrypt_msg } from "../../utilities/messages";

export const ChatsShow = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [chat, setChat] = useState({
    session: {
      users: [],
    },
    messages: [],
  });

  let { chat_id } = useParams();

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    // retrieve chat
    api.get(`/api/v1/chats/${chat_id}?_=${Date.now()}`, config).then((res) => {
      setChat(res.data);
      dispatch_chat(res.data).then(() => {
        let new_chat = res.data;
        new_chat.messages.forEach((msg) => {
          decryptor(new_chat.session.id, msg);
        });
      });
    });
  }, []);

  const dispatch_chat = async (chat) => {
    await dispatch(addChat(chat))
  }

  const decryptor = (chat_id, msg) => {
    const re = /^-----BEGIN PGP MESSAGE.*/;
    console.log(msg);
    if(re.test(msg.message)) {
      let encrypted_msg;
      if(msg.created_by !== user.session.id) {
        encrypted_msg = msg.message;
      } else {
        encrypted_msg = msg.sender_message;
      }
      decrypt_msg({msg: encrypted_msg, user: user}).then((decrypted_msg) => {
        let new_msg = JSON.parse(JSON.stringify(msg));
        new_msg.message = decrypted_msg;
        console.log("decrypt addMessageToChat", new_msg);
        dispatch(addMessageToChat({chat_id: chat_id, msg: new_msg}));
      });
    }
  }

  const scrollToBottom = () => {
    ref.current.scrollIntoView({ behavior: "auto", block: "end" });
  };

  return (
    <div ref={ref}>
      <Messages scrollToBottom={scrollToBottom} />
      <ChatBox chat={chat} />
    </div>
  );
};