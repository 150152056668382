import { TableRow, TableCell, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import BackupIcon from "@mui/icons-material/Backup";

import Checkbox from "@mui/material/Checkbox";
import { api } from "../shared/api";
import { updateWallet } from "../../store/walletSlice";

import { Download } from "./download";
import { useNavigate } from "react-router-dom";
import { RemoveWallet } from "./removeWallet";

export const EthereumWallet = ({ wallet, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePublic = () => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };
    const params = {
      public: !!wallet.public,
    };
    api
      .post(`/api/v1/wallets/${wallet.public_address}/public`, params, config)
      .then((res) => {
        dispatch(updateWallet(res.data));
      });
  };

  const renderTokens = (wallet) => {
    if ("tokens" in wallet) {
      return wallet["tokens"].map((token) => {
        //console.log(token);
        return (
          <TableRow style={{ backgroundColor: "#EEEEEE" }}>
            <TableCell>
              {token.token_name} ({token.token_shortname})
            </TableCell>
            <TableCell>{token.contract_type}</TableCell>
            <TableCell></TableCell>
            <TableCell>{token.amount}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{wallet.wallet_type}</TableCell>
        <TableCell>{wallet.wallet_type}</TableCell>
        <TableCell>
          <Typography
            onClick={() =>
              navigate(`/wallets/${wallet.wallet_type}/${wallet.id}`)
            }
          >
            {wallet.public_address}
          </Typography>
        </TableCell>
        <TableCell>{wallet.balance}</TableCell>
        <TableCell>{wallet.value}</TableCell>
        <TableCell>
          <IconButton aria-label="backup">
            <BackupIcon />
          </IconButton>
          <RemoveWallet wallet={wallet} />
          <Download wallet={wallet} />
        </TableCell>
        <TableCell>{wallet.backedup ? "🟢" : "🔴"}</TableCell>
        <TableCell>
          <Checkbox
            onClick={() => {
              togglePublic();
            }}
            checked={wallet.public}
          />
        </TableCell>
        <TableCell>
          <Checkbox checked={wallet.default} />
        </TableCell>
      </TableRow>
      {renderTokens(wallet)}
    </>
  );
};
