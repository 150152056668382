export const en = {
  translation: {
    Home: {
      hero: {
        about1: 'Salt is an integrated encryption and cryptocurrency chat app and platform.',
        about2: 'Chat and send cryptocurrencies over private messages with anyone.',
        webapp: 'Web app',
      },
      downloads: {
        title: 'Downloads'
      },
      body: {
        chat1: 'Fully secure and private messages',
        chat2: 'Encrypted using OpenPGP PKE cryptography',
        crypto1: 'Variety of cryptocurrency and token wallets',
        crypto2: 'Currently supports: Bitcoin, Ethereum, Dogecoin',
        profiles1: 'Verified accounts',
        profiles2: 'Reviews and rating',
        api1: 'Build any type of bot',
        api2: 'Developer API to integrate whatever you can imagine'
      }
    }
  }
};