export const ja = {
  translation: {
    Home: {
      hero: {
        about1: 'Salt は、統合された暗号化と暗号通貨のチャット アプリおよびプラットフォームです。',
        about2: '誰とでもチャットしたり、プライベート メッセージで仮想通貨を送信したりできます。',
        webapp: 'ウェブアプリ',
      },
      downloads: {
        title: 'ダウンロード'
      },
      body: {
        chat1: '完全に安全なプライベート メッセージ',
        chat2: 'OpenPGP PKE 暗号化を使用して暗号化',
        crypto1: 'さまざまな暗号コインおよびトークン ウォレット',
        crypto2: '現在サポートされているビットコイン、イーサリアム、ドージコイン',
        profiles1: '確認済みアカウント',
        profiles2: 'レビューと評価',
        api1: 'あらゆるタイプのボットを構築',
        api2: '想像できるものは何でも統合するための開発者 API'
      }
    }
  }
}