import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BasicTokenSelect } from "../../shared/select";

export const CurrencyTokenSelect = ({ selectedWallet, setSelectedToken }) => {
  const wallets = useSelector((state) => state.wallets);

  const [selectedTokenId, setSelectedTokenId] = useState(null);

  useEffect(() => {
    if (selectedWallet !== undefined && "tokens" in selectedWallet) {
      let token = selectedWallet["tokens"].find((t) => t.id == selectedTokenId);
      console.log(selectedTokenId);
      setSelectedToken(token);
    }
  }, [selectedTokenId]);

  const renderTokens = () => {
    if (selectedWallet !== undefined && "tokens" in selectedWallet) {
      return (
        <BasicTokenSelect
          label="Tokens"
          setSelected={setSelectedTokenId}
          selected={selectedTokenId}
          options={buildTokenOptions()}
        />
      );
    }
  };

  const buildTokenOptions = () => {
    let options = [];
    selectedWallet["tokens"].map((token) => {
      options.push(token);
      return true;
    });
    return options;
  };

  return <>{renderTokens()}</>;
};
