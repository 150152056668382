import React, { useEffect, useState } from "react";

import { api } from "../shared/api";

import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "../../store/userSlice";

import { HOST } from "../../store/constants";
import { useNavigate } from "react-router-dom";

import Container from "@mui/system/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { Alert, Tooltip } from "@mui/material";

import { SaltSubHeader } from "../../layouts/saltSubHeader";

import { updateKeys } from "../../store/userSlice";
import { updateWallet } from "../../store/walletSlice";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();


  useEffect(() => {
    if (user && user.loggedIn === true) {
      downloadWallets();
      downloadKeys();
      navigate("/contacts");
    }
  }, [user])

  const downloadKeys = () => {
    const config = {
      headers: { 
        'Authorization': `${user.token}` 
      },
    };
    api.get(`/api/v1/userkeys?_=${Date.now()}`, config).then((res) => {
      //console.log(res);
      dispatch(updateKeys(res.data));
      return true;
    });
  };

  const downloadWallets = () => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };
    api.get(`/api/v1/wallets?_=${Date.now()}`, config).then((res) => {
      res.data.map((wallet) => {
        dispatch(updateWallet(wallet));
        return true;
      });
    });
  };

  const submitAuth = () => {
    let params = {
      user: {
        email: email,
        password: password,
      },
    };
    api.post(`${HOST}/auth/sign_in?_=${Date.now()}`, params).then((res) => {
      dispatch(
        userLogin({
          session: res.data,
          token: res.headers.get("Authorization"),
        })
      );

    }).catch((error) => {
      console.log(error, params);
      setErrors("Invalid email or password.");
    });
  };

  const renderErrors = () => {
    if(errors !== null) {
      return (
        <Alert severity="error">{errors}</Alert>
      )
    }
  }

  return (
    <>
      <SaltSubHeader />
      <Container maxWidth="xs">
        <Grid
          container
          justify="center"
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid xs={12}>
            <h1>Login</h1>
          </Grid>
          <Grid xs={12}>
            { renderErrors() }
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12}>
            <Button variant="contained" onClick={() => submitAuth()}>
              Authenticate
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
