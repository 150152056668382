import { decrypt } from "./encrypt"

// takes an entire chat record and decrypts individual msgs

export const decrypt_msgs = async ({user, chat}) => {
  var new_messages = JSON.parse(JSON.stringify(chat.messages));
  var decrypted_messages = JSON.parse(JSON.stringify(chat.messages))

  new_messages.forEach((msg, i) => {
    const re = /^-----BEGIN PGP MESSAGE.*/;
    if(msg.created_by !== user.session.id && re.test(msg.message)) {
      decrypt_msg({ msg: msg.message, receiver_private_key: user.PKEkeys.private_key }).then((dm) => {
        //console.log(decrypted_messages[i], dm);
        try {
          decrypted_messages[i].message = dm;
        } catch (err) { 
          console.log("FUCK", err, dm);
        }
      });
    } else if(msg.created_by === user.session.id) {
      decrypted_messages[i] = msg; 
    }
  });

  var decrypted_chat = {
    session: chat.session,
    messages: decrypted_messages,
  }

  return decrypted_chat;
}

export const decrypt_msg = ({msg, user}) => {
  const receiver_private_key = user.PKEkeys.private_key;

  return decrypt({
    encrypted_message: msg,
    receiver_privateKey: receiver_private_key
  })
}