import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
//import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
//import AddIcon from '@mui/icons-material/Add';
import Divider from "@mui/material/Divider";
import ChatIcon from "@mui/icons-material/Chat";
import Typography from "@mui/material/Typography";

import { api } from "../shared/api";

export const ContactsIndex = () => {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const user = useSelector((state) => state.user);

  const config = {
    headers: { Authorization: `${user.token}` },
  };

  useEffect(() => {
    api.get(`/api/v1/contacts?_=${Date.now()}`, config).then((res) => {
      setContacts(res.data);
    });
  }, []);

  const createChat = (contact_id) => {
    api
      .post(`api/v1/chats?_=${Date.now()}`, { contact_id: contact_id }, config)
      .then((res) => {
        navigate(`/chats/${res.data.id}`);
      });
  };

  const renderContacts = () => {
    return contacts.map((contact, index) => (
      <>
        <ListItem
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                createChat(contact.user_id);
              }}
            >
              <ChatIcon />
            </IconButton>
          }
        >
          <ListItemText
            primary={`${contact.user.display_name} (@${contact.user.username})`}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Status
                </Typography>
                {" — Away from keyboard"}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
      </>
    ));
  };

  return (
    <>
      <Box textAlign="center">
        <Button
          sx={{
            margin: 3,
          }}
          size="large"
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={() => navigate("/contacts/new")}
        >
          Add a new contact
        </Button>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Divider />
          {renderContacts()}
        </List>
      </Box>
    </>
  );
};
