import React from "react";
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { AccountMenu } from "./accountMenu";

export const AuthStatus = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const notLoggedIn = () => {
    return (
      <>
        <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>
        <Button color="inherit" onClick={() => navigate('/signup')}>Signup</Button>
      </>
    );
  }

  const loggedIn = () => {
    return (
      <AccountMenu />
    );
  }

  const authStatus = () => {
    if(user.loggedIn === true) {
      return loggedIn();
    } else {
      return notLoggedIn();
    }
  }

  return (
    <>
      {authStatus()}
    </>
  );
}