// CoinKey saved me so much time I was wasting
// http://cryptocoinjs.com/guide/getting-started/
var CoinKey = require('coinkey');

const bitcoin = {
  generate: () => {
    var ck = new CoinKey.createRandom();
    //console.log("Private Key (Wallet Import Format): " + ck.privateWif);
    //console.log("Private Key (Hex): " + ck.privateKey.toString('hex'));
    //console.log("Public Address: " + ck.publicAddress);
    return ck;
  },
}

export { bitcoin }