import { Alert, Container, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../shared/api";
import { addTransfers } from "../../store/transferSlice";
import { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom";
import { TransferRefreshButton } from "./TransferRefreshButton";

export const TransfersIndex = () => {
  const user = useSelector((state) => state.user);
  const transfers = useSelector((state) => state.transfers);
  const dispatch = useDispatch();

  useEffect(() => {
    getTransfers();
  }, []);

  const getTransfers = () => {
    const config = {
      headers: { Authorization: `${user.token}` },
    };

    api.get(`/api/v1/transfers?_=${Date.now()}`, config).then((res) => {
      dispatch(addTransfers(res.data));
    });
  };

  const buildTransfers = () => {
    let records = transfers.map((transfer) => {
      console.log(transfer.receiver.id, user.session.id);
      return (
        <>
          <Grid xs={3}>
            {transfer.status === "Failed" ? (<ErrorIcon/>) : ''}
            {transfer.status === "Pending" ? (<PendingIcon/>) : ''}
            {transfer.status === "Confirmed" ? (<CheckCircleIcon/>) : ''}
            {user.session.id === transfer.receiver.id ? (
              <CallReceivedIcon />
            ) : (
              <CallMadeIcon />
            )}
            {`${transfer.sender.display_name} (@${transfer.sender.username})`}
            <ArrowRightIcon sx={{ top: "6px", position: "relative" }} />
            {`${transfer.receiver.display_name} (@${transfer.receiver.username})`}
            <TransferRefreshButton transfer={transfer} />
          </Grid>
          <Grid xs={12}>Amount: {transfer.amount} {transfer.transfer_type}</Grid>
          <Grid xs={12}>
            <Accordion sx={{ backgroundColor: "#3f66a9", color: "#FFF" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid xs={12}>From: {transfer.sender_wallet_address}</Grid>
                <Grid xs={12}>To: {transfer.receiver_wallet_address}</Grid>
                <Grid xs={12}>
                  Tx Id:{" "}
                  <a
                    href={`https://sepolia.etherscan.io/tx/${transfer.tx_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {transfer.tx_id}
                  </a>
                </Grid>
                <Grid xs={12}>Message: {transfer.message}</Grid>
                { transfer.status === "Failed" ? (<Grid xs={12}>{transfer.service_request_response}</Grid>) : '' }
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      );
    });
    if(records.length > 0) {
      return records;
    } else {
      return (
        <Alert severity="info">
          No transfers yet! <Link to="/wallets">Add a crypto wallet</Link> and start.
        </Alert>
      )
    }
  };

  return (
    <Container>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid xs={12}>
          <Typography variant="h4">Transfers</Typography>
        </Grid>
        {buildTransfers()}
      </Grid>
    </Container>
  );
};
