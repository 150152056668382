import { ethers } from "ethers";
import { erc20 as erc20_abi } from "../abis/erc20";

export const createTx = async ({
  receiver_wallet, 
  amount, 
  nonce,
  gas_price
}) => {
  const value = ethers.utils.parseEther(amount);

  const gasPrice = gas_price; //ethers.utils.parseUnits("11", "gwei");
  const gasLimit = 0x5208; //ethers.utils.parseUnits("100", "gwei");

  console.log("createTx", value.toHexString(), gasLimit, gasPrice);

  const tx = {
    to: receiver_wallet.public_address,
    value: value.toHexString(),
    gasLimit: gasLimit,
    nonce: nonce,
    chainId: 11155111, // Sepolia
    gasPrice: gasPrice,
  };

  return tx;
};


export const createTx1 = ({
  receiver_wallet, 
  amount, 
  nonce,
  gas_price
}) => {

  /* !!! NEEED TO CONVERT THIS TO ASYNC/AWAIT function */

  const value = ethers.utils.parseEther(amount);

  /*
  
  Gas Fees = Gas Units (Limit) x (Base Fee + Tip)

  Gas Units(Limit): 
    It’s the maximum amount a single transaction consumes.
    This limit guarantees the execution of the transaction and is fixed at 21,000 gas units for a simple ETH transfer.
    When the new block is mined, the base fee is removed from circulation or “burned.”
  Base Fee: 
    It’s the minimum amount recording a transaction on the Ethereum network needs.
    It’s not fixed therefore it will decrease or increase according to demand and engagement on the blockchain at a certain time.
    You can always find the Base fee on ETH gas station and currently, it’s 11 Gwei.

  */

  const gasPrice = gas_price; //ethers.utils.parseUnits("11", "gwei");
  const gasLimit = 0x5208; //ethers.utils.parseUnits("100", "gwei");

  console.log("createTx", value.toHexString(), gasLimit, gasPrice);

  let tx = {
    to: receiver_wallet.public_address,
    value: value.toHexString(),
    gasLimit: gasLimit,
    nonce: nonce,
    chainId: 11155111, // Sepolia
    gasPrice: gasPrice,
  }

  return (async() => {return tx});
}

export const createSignedTx = ({sender_wallet, tx}) => {
  const wallet = new ethers.Wallet(sender_wallet.private_key);
  let signed_tx = wallet.signTransaction(tx)
  return signed_tx;
}

// originally used web3: https://ethereum.stackexchange.com/questions/21665/web3-js-import-contract-abi-from-json-file
export const createErc20Tx = ({ sender_wallet, receiver_wallet, amount, nonce, gas_price }) => {

  const value = ethers.utils.parseEther(amount);

  console.log({sender_wallet, receiver_wallet, amount, value, nonce, gas_price});

  // load abi for ERC20
  var abi = erc20_abi;

  const ERC20_CONTRACT_ADDRESS = "0xeef212686cf705db4778ca569e09325bce3031fe";

  const signer = new ethers.Wallet(sender_wallet.private_key);

  // specify contract

  console.log({ERC20_CONTRACT_ADDRESS, abi, signer});

  const contract = new ethers.Contract(ERC20_CONTRACT_ADDRESS, abi, signer);

  // Using: https://docs.ethers.org/v5/api/contract/example/#example-erc-20-contract--connecting-to-a-contract

  return contract.populateTransaction.transfer(receiver_wallet.public_address, value).then((unsigned_tx) => {
    // create signed transaction
    
    console.log({unsigned_tx});

    const gasPrice = gas_price; //ethers.utils.parseUnits("11", "gwei");
    //const gasLimit = 0x5208; //ethers.utils.parseUnits("100", "gwei");
    const gasLimit = 0x186A0;

    console.log("createTx", value, gasLimit, gasPrice);

    let tx = {
      to: ERC20_CONTRACT_ADDRESS, //receiver_wallet.public_address,
      // value: value.toHexString(),
      gasLimit: gasLimit,
      nonce: nonce,
      chainId: 11155111, // Sepolia
      gasPrice: gasPrice,
      // contract data
      data: unsigned_tx.data,
    }

    console.log({tx});

    return tx;
  });

}